import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from '../components/header';
import Footer from '../components/footer';
import './Services.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { serviceInfo } from '../pages/serviceInfo';
import React, { useState, useEffect } from 'react';

function Services() {
  const { id } = useParams();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openIndex, setOpenIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(0); // Nuevo estado para el botón activo
  const [loadingImages, setLoadingImages] = useState({}); // Estado para las imágenes cargando

  const toggleMenu = (index) => {
    setOpenIndex(index);
    setActiveButton(index); // Actualiza el botón activo
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isFullScreen) {
        document.body.classList.add('disable-scroll');
      } else {
        document.body.classList.remove('disable-scroll');
      }
    };

    handleScroll();

    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, [isFullScreen]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsFullScreen(true);
  };

  const handleImageLoad = (index) => {
    setLoadingImages(prev => ({ ...prev, [index]: false }));
  };

  const exitFullScreen = () => {
    setSelectedImage(null);
    setIsFullScreen(false);
  };

  let info;

  for (let i = 0; i < serviceInfo.length; i++) {
    if (serviceInfo[i].link === id) {
      info = serviceInfo[i];
    }
  }

  return (
    <div>
      <AppHeader />
      <div className="container-services">
        {isFullScreen && (
          <div className="fullscreen-overlay">
            <img src={selectedImage} alt="Full screen"></img>
            <i id="x-icon1" className="fa-solid fa-xmark" onClick={exitFullScreen}></i>
          </div>
        )}
        <Container>
          <Row>
            <Col>
              <div className="infoA-services">
                <p className="title-services">{info.name}</p>
                <p className="text-services">{info.description}</p>
              </div>
              <div className="infoB-services">
                <Row xs={1} md={2}>
                  {
                    info.info.map((service, key) =>
                      <Col key={key}>
                        <button 
                          className={`textc-services ${activeButton === key ? 'active' : ''}`} 
                          onClick={() => toggleMenu(key)}>
                          <p className="text-services"> {service}</p>
                        </button>
                      </Col>
                    )}
                </Row>
              </div>
              <div className="images-section">
                <Row xs={1} md={2}>
                  {info.images.map((serviceImage, index) =>
                    serviceImage.infoName === info.info[openIndex] && serviceImage.imgs.map((img, imgKey) => (
                      <Col key={imgKey}>
                        <div className="imagesc-services" key={imgKey}>
                          {loadingImages[imgKey] !== false && (
                            <div className="spinner-container">
                              <Spinner animation="border" />
                            </div>
                          )}
                          <img
                            className="image-services"
                            src={img}
                            alt=""
                            onLoad={() => handleImageLoad(imgKey)}
                            onClick={() => handleImageClick(img)}
                            style={{ display: loadingImages[imgKey] !== false ? 'none' : 'block' }}
                          />
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Services;