import gal1 from "../gallery/gal1.jpeg"
import gal2 from "../gallery/gal2.jpeg"
import gal3 from "../gallery/gal3.jpeg"
import gal4 from "../gallery/gal4.jpeg"
import gal5 from "../gallery/gal5.jpeg"
import gal6 from "../gallery/gal6.jpeg"
import gal7 from "../gallery/gal7.jpeg"
import gal8 from "../gallery/gal8.jpeg"
import gal9 from "../gallery/gal9.jpeg"
import gal10 from "../gallery/gal10.jpeg"
import gal11 from "../gallery/gal11.jpeg"
import gal12 from "../gallery/gal12.jpeg"
import gal13 from "../gallery/gal13.jpeg"
import gal14 from "../gallery/gal14.jpg"
import gal15 from "../gallery/gal15.jpg"
import gal16 from "../gallery/gal16.jpg"
import gal17 from "../gallery/gal17.jpg"
import gal18 from "../gallery/gal18.jpg"
import gal19 from "../gallery/gal19.jpeg"
import gal20 from "../gallery/gal20.jpeg"
import gal21 from "../gallery/gal21.jpg"
import gal22 from "../gallery/gal22.jpg"
import gal23 from "../gallery/gal23.jpg"
import gal24 from "../gallery/gal24.jpg"

export const imageStock = [
    {
        imageR: gal1
    },
    {
        imageR: gal2
    },
    {
        imageR: gal3
    },
    {
        imageR: gal4
    },
    {
        imageR: gal5
    },
    {
        imageR: gal6
    },
    {
        imageR: gal7
    },
    {
        imageR: gal8
    },
    {
        imageR: gal9
    },
    {
        imageR: gal10
    },
    {
        imageR: gal11
    },
    {
        imageR: gal12
    },
    {
        imageR: gal13
    },
    {
        imageR: gal14
    },
    {
        imageR: gal15
    },
    {
        imageR: gal16
    },
    {
        imageR: gal17
    },
    {
        imageR: gal18
    },
    {
        imageR: gal19
    },
    {
        imageR: gal20
    },
    {
        imageR: gal21
    },
    {
        imageR: gal22
    },
    {
        imageR: gal23
    },
    {
        imageR: gal24
    },
]