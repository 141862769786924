import Container from 'react-bootstrap/Container';
import './contact.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Contact() {
    return (
        <div id="Contacto">
            <div className='main-contact'>
                <Container>
                    <Row>
                        <Col className='contact-col'>
                            <div className='contact-form'>
                            <p className="contact-title">Contáctanos</p>
                                <p className='contact-subtext'>¡Utiliza nuestro formulario para conectarte directamente con nosotros!</p>
                                <form action="mailto:cvillarreal@trecemercantil.com" method="post" encType="text/plain">
                                    <label className='contact-label'>Nombre:</label>{' '}
                                    <input className='contact-input' id="POST-name" type="text" name="Nombre" required/>
                                    <br />
                                    <label className='contact-label'>Telefono:</label>{' '}
                                    <input className='contact-input' id="POST-phone" pattern="[0-9]{10}" type="text" name="Telefono" required/>
                                    <br />
                                    <label className='contact-label'>Correo:</label>{' '}
                                    <input className='contact-input' id="POST-mail" type="email" name="Correo" required/>
                                    <label className='contact-label'>Mensaje:</label>{' '}
                                    <textarea className='contact-input' id="POST-message" type="textarea" name="Mensaje" required></textarea>
                                    <input type="hidden" id="custId" name="mensaje" value="Buscando contacto con la empresa. Favor de comunicarse."></input>
                                    <br />
                                    <button className="main-button" type="submit" value="Contactar">Cóntactar</button>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Contact;