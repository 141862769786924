import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from '../components/header';
import Footer from '../components/footer';
import './Gallery.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import { useState, useRef, useEffect, useCallback } from 'react';
import { imageStock } from '../img/gallery/imageStock';

function Gallery() {
    const [index, setIndex] = useState(0);
    const thumbnailsRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        document.body.classList.toggle('disable-scroll', isFullScreen);
        return () => document.body.classList.remove('disable-scroll');
    }, [isFullScreen]);

    const handleImageClick = useCallback((image) => {
        setSelectedImage(image);
        setIsFullScreen(true);
    }, []);

    const exitFullScreen = useCallback(() => {
        setSelectedImage(null);
        setIsFullScreen(false);
    }, []);

    const handleSelect = useCallback((selectedIndex) => {
        setIndex(selectedIndex);
    }, []);

    useEffect(() => {
        if (thumbnailsRef.current && thumbnailsRef.current.children[index]) {
            const selectedThumbnail = thumbnailsRef.current.children[index];
            const thumbnailWidth = selectedThumbnail.offsetWidth;
            const containerWidth = thumbnailsRef.current.offsetWidth;
            const scrollPosition = Math.max(selectedThumbnail.offsetLeft - (containerWidth - thumbnailWidth) / 2, 0);
            thumbnailsRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [index]);

    return (
        <>
            <AppHeader />
            <div className="gallery-container">
                {isFullScreen && (
                    <div className="fullscreen-overlayI" onClick={exitFullScreen}>
                        <img src={selectedImage} alt="Full screen" />
                        <i id="x-icon1" className="fa-solid fa-xmark" onClick={exitFullScreen}></i>
                    </div>
                )}
                <Container>
                    <div className="gallery-textc">
                        <p className="gallery-title">Galería</p>
                        <p className="gallery-text">Un vistazo a nuestros proyectos</p>
                    </div>
                    <div className="galleryI-container">
                        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} indicators={false}>
                            {imageStock.map((image, idx) => (
                                <Carousel.Item key={idx}>
                                    <img src={image.imageR} className="gallery-images" alt={`Gallery ${idx + 1}`} onClick={() => handleImageClick(image.imageR)} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <div className="carousel-thumbnails" ref={thumbnailsRef}>
                            <Row className="flex-nowrap">
                                {imageStock.map((image, idx) => (
                                    <Col key={idx} xs={3} className="thumbnail-col">
                                        <img
                                            src={image.imageR}
                                            className={`thumbnail ${index === idx ? 'active' : ''}`}
                                            alt={`Thumbnail ${idx + 1}`}
                                            onClick={() => handleSelect(idx)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    );
}

export default Gallery;