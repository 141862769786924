import Container from 'react-bootstrap/Container';
import './footer.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import treceAlt from '../img/trece_alt.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='main-footer'>
            <div className="footer-row"></div>
            <Container>
                <Row>
                    <Col className="imagef-container">
                        <img
                            className="footer-image"
                            src={treceAlt}
                            alt="logo_oscuro"
                        />
                    </Col>
                    <Col className="footer-textc">
                        <p className="footer-menu">Menu de navegación</p>
                        <Link to='/'><p className="footer-text">Home</p></Link>
                        <Link to='/'><p className="footer-text">Servicios</p></Link>
                        <Link to='/Nosotros'><p className="footer-text">Nosotros</p></Link>
                        <Link to='/Galeria'><p className="footer-text">Galeria</p></Link>
                    </Col>
                    <Col className="footer-textc">
                        <p className="footer-menu">Redes Sociales</p>
                        <p className="footer-icon">
                            <a href="https://www.facebook.com/profile.php?id=100076292137446&locale=es_LA"className="footer-text"><span><i className="fa-brands fa-facebook"></i></span>Facebook</a>
                        </p>
                        <p className="footer-icon">
                            <a href="https://www.instagram.com/trece_mercantil?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="footer-text"><span><i className="fa-brands fa-instagram"></i></span>Instagram</a>
                        </p>
                        <p className="footer-icon">
                            <a href="https://wa.me/6181172776" className="footer-text"><span><i className="fa-brands fa-whatsapp"></i></span>WhatsApp</a>
                        </p>
                    </Col>
                </Row>
            </Container>
            <div className="footer-row"></div>
        </div>
    );
}

export default Footer;