import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './services.css'
import { Link } from 'react-router-dom';
import { serviceInfo } from '../pages/serviceInfo';

function Services() {
    return (
        <div className="service-container">
            <p className="services-title">Nuestros servicios</p>
            <Container>
                <Row xs={1} md={2}>
                    {
                        serviceInfo.map((service, key) =>
                            <Col key={key}>
                                <Link className="services-link" to={{ pathname: `/Servicios/${service.link}` }}>
                                    <div className={service.customClass}>
                                        <p className="services-subtitle"><span><i className={service.customIcon}></i></span> {service.name}</p>
                                        <p className="services-subtext">{service.description}</p>
                                    </div>
                                </Link>
                            </Col>
                        )
                    }
                </Row>
            </Container>
        </div>
    );
}

export default Services;