import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import trecelogo from '../img/trece_logo.png'
import './header.css'
import { serviceInfo } from '../pages/serviceInfo';
import React, { useState } from 'react';

function AppHeader() {

  return (
    <Navbar collapseOnSelect expand="lg" className="header">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={trecelogo}
            width="40"
            height="35"
            className="d-inline-block align-top"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="burger"><i className="fa-solid fa-bars"></i></Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link to="/" className="header-text">Home</Link>
            <div className="dropdown">
              <button className="dropbtn">Servicios{" "}
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                {serviceInfo.map((service, key) =>
                  <Link key={key} to={{ pathname: `/Servicios/${service.link}` }}><p className="header-text">{service.name}</p></Link>
                )}
              </div>
            </div>
            <Link to="/Nosotros" className="header-text">Nosotros</Link>
            <Link to="/Galeria" className="header-text">Galería</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;