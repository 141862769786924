import Container from 'react-bootstrap/Container';
import './map.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Map() {
    return (
        <div>
            <div className='main-map'>
                <Container className='mainc-map'>
                    <Row xs={1} md={2}>
                        <Col className='colr-map'>
                        <div className='div-map'>
                        <p className="map-title">Donde encontranos</p>
                        <a href="https://www.google.com/maps/place/Amanecer+706,+Brisas+Diamante,+34235+Durango,+Dgo./@24.0507878,-104.602978,18.5z/data=!4m5!3m4!1s0x869bb7031b7b1abf:0xf42ef6b87885e19b!8m2!3d24.0509783!4d-104.6023066?entry=ttu" className="map-text"><span><i className="fa-solid fa-location-dot"></i></span> Privada Amanecer 706 Victoria de Durango, DGO 34113</a>
                        <br/>
                        <a href="tel:+6181172776" className="map-text"><span><i className="fa-solid fa-phone"></i></span> 6181172776</a>
                        <br/>
                        <br/>
                        <p className="map-title">Redes Sociales</p>
                        <a  href="https://www.facebook.com/profile.php?id=100076292137446&locale=es_LA" className="map-texta"><span><i className="fa-brands fa-facebook"></i> Facebook</span></a>{' '}
                        <a  href="https://www.instagram.com/trece_mercantil?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="map-texta"><span><i className="fa-brands fa-instagram"></i> Instagram</span></a>{' '}
                        <a  href="https://wa.me/6181172776" className="map-texta"><span><i className="fa-brands fa-whatsapp"></i> WhatsApp</span></a>{' '}
                        </div>
                        </Col>
                        <Col className='coll-map'>
                        <iframe title="myMap" className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1288.150740008975!2d-104.60297798552052!3d24.05078779738899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7031b7b1abf%3A0xf42ef6b87885e19b!2sAmanecer%20706%2C%20Brisas%20Diamante%2C%2034235%20Durango%2C%20Dgo.!5e0!3m2!1ses-419!2smx!4v1722532399322!5m2!1ses-419!2smx"loading="lazy"></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Map;