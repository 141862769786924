import render1 from "../img/services/const_res/render1.jpg"
import render4 from "../img/services/const_res/render4.jpg"
import remo2 from "../img/services/const_res/remo2.jpg"
import casa1 from "../img/services/const_res/casa1.jpg"
import constI1 from "../img/services/const_ind/constI1.jpg"
import constI2 from "../img/services/const_ind/constI2.jpeg"
import constI3 from "../img/services/const_ind/constI3.jpeg"
import constI4 from "../img/services/const_ind/constI4.jpeg"
import topo1 from "../img/services/topo/topo1.jpeg"
import topo2 from "../img/services/topo/topo2.jpeg"
import topo3 from "../img/services/topo/topo3.jpeg"
import topo4 from "../img/services/topo/topo4.jpeg"
import otro1 from "../img/services/otros/otro1.jpeg"
import otro2 from "../img/services/otros/otro2.jpg"
import otro3 from "../img/services/otros/otro3.jpeg"

export const serviceInfo = [
    {
        name: "Construcción Residencial",
        description: "Nuestra experiencia en construcción residencial abarca desde acogedoras viviendas unifamiliares hasta modernos complejos residenciales. Nos enorgullecemos de crear espacios personalizados que reflejen el estilo y las necesidades de cada cliente. Con un enfoque en la calidad, sostenibilidad y diseño innovador, garantizamos que cada proyecto residencial se convierta en un lugar donde las familias puedan crecer y prosperar.",
        info: ["Diseño Arquitectonico", "Renders (Visualizaciones)", "Remodelaciones", "Casa Habitación"],
        images : [
            {infoName : "Diseño Arquitectonico", imgs : [casa1, remo2, render4, render1]},
            {infoName : "Renders (Visualizaciones)",imgs : [casa1, remo2, render4, render1]},
            {infoName : "Remodelaciones", imgs : [casa1, remo2, render4, render1]},
            {infoName : "Casa Habitación",imgs : [casa1, remo2, render4, render1]}
            ],
        link: "Construccion_Residencial",
        customClass : "services-left",
        customIcon : "fa-solid fa-trowel-bricks"
    },
    {
        name: "Construcción Industrial",
        description: "Trece se especializa en la construcción industrial, ofreciendo soluciones robustas y eficientes para el sector manufacturero, logístico y de servicios. Comprendemos las necesidades específicas de cada industria y trabajamos en estrecha colaboración con nuestros clientes para asegurar que sus instalaciones sean seguras, funcionales y estén a la vanguardia tecnológica. Nuestra capacidad para manejar proyectos de gran escala con precisión y puntualidad nos distingue en el mercado.",
        info: ["Minería", "Moviemientos de Tierra", "Naves Industriales"],
        images : [
            {infoName : "Minería", imgs : [constI1, constI2, constI3, constI4]},
            {infoName : "Moviemientos de Tierra", imgs : [constI1, constI2, constI3, constI4]},
            {infoName : "Naves Industriales", imgs : [constI1, constI2, constI3, constI4]},
            ],
        link: "Construccion_Industrial",
        customClass : "services-right",
        customIcon : "fa-solid fa-helmet-safety"
    },
    {
        name: "Topografía",
        description: "La precisión es fundamental en la topografía, y en Trece, ofrecemos servicios de topografía de alta calidad que garantizan la exactitud en cada etapa del proyecto. Utilizamos tecnología avanzada para realizar estudios topográficos detallados que sirven como base sólida para cualquier construcción. Nuestro equipo de expertos asegura que cada proyecto comience con el pie derecho.",
        info: ["Levantamientos", "Renta de Equipos", "Proyectos", "Deslindes", "Montajes de Estructura"],
        images : [
            {infoName : "Levantamientos", imgs : [topo1, topo4, topo3, topo2]},
            {infoName : "Renta de Equipos", imgs : [topo1, topo4, topo3, topo2]},
            {infoName : "Proyectos", imgs : [topo1, topo4, topo3, topo2]},
            {infoName : "Deslindes", imgs : [topo1, topo4, topo3, topo2]},
            {infoName : "Montajes de Estructura", imgs : [topo1, topo4, topo3, topo2]},
            ],
        link: "Topografia",
        customClass : "services-leftd",
        customIcon : "fa-solid fa-pen-ruler"
    },
    {
        name: "Otros Servicios",
        description: "Además de nuestras especialidades en construcción residencial, industrial y topografía, Trece ofrece una gama de servicios complementarios que incluyen supervisión y gestión de proyectos, herreria y renta de equipo. Nos esforzamos por ser un socio integral para nuestros clientes, proporcionando soluciones completas que abarcan desde la concepción del proyecto hasta su finalización y más allá. Con Trece, cada detalle está cubierto.",
        info: ["Supervisión", "Herreria", "Equipo Ligero"],
        images : [
            {infoName : "Supervisión", imgs : [otro1, otro2, otro3]},
            {infoName : "Herreria", imgs : [otro1, otro2, otro3]},
            {infoName : "Equipo Ligero", imgs : [otro1, otro2, otro3]},
            ],
        link: "Otros",
        customClass : "services-rightd",
        customIcon : "fa-solid fa-screwdriver-wrench"
    }
]