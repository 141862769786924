import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './mainImage.css';
import stock1 from '../img/main/main21.jpeg';
import stock2 from '../img/main/main07.jpg';
import stock3 from '../img/main/main25.jpeg';
import { Link } from 'react-router-dom';

function MainImage() {
    return (
        <div className="mainI-container">
            <Container>
                <Row xs={1} md={2}>
                    <Col>
                        <div className="mainI-text">
                            <p className="mainI-title">Pasión por la excelencia, construyendo calidad y confianza.</p>
                        </div>
                        <div className="mainI-subtext">
                            <p>En Trece, nos dedicamos a construir con calidad y compromiso, transformando tus ideas en realidades duraderas.</p>
                                <div className="mainI-buttons">
                                    <a href="/#Contacto" ><button className="mainI-button">Contáctanos</button></a>{' '}
                                    <Link to="/Galeria" ><button className="mainI-button">Galería</button></Link>
                                </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="imageM-container">
                            <img
                                className="mainI-image"
                                src={stock1}
                                width="100%"
                                height="100%"
                                alt=""
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="imageM-container">
                            <img
                                className="mainI-image"
                                src={stock2}
                                width="100%"
                                height="100%"
                                alt=""
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="imageM-container">
                            <img
                                className="mainI-image"
                                src={stock3}
                                width="100%"
                                height="100%"
                                alt=""
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MainImage;