import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Us from './pages/Us';
import Gallery from './pages/Gallery';
import Services from './pages/Services.jsx';
import ScrollToTop from './pages/ScrollToTop.jsx';
  
  function App() {
      return (
          <div className="App">
              <BrowserRouter>
              <ScrollToTop />
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="Servicios/:id" element={<Services />} />
                      <Route path="Nosotros/" element={<Us />} />
                      <Route path="Galeria/" element={<Gallery />} />
                  </Routes>
              </BrowserRouter>
          </div>
      );
  }
  
  export default App;