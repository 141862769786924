import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './slider.css'
import gallery from '../img/gallery/galgif.gif'
import { Link } from 'react-router-dom';

function Slider() {

    return (
        <div className="slider-container">
            <Container>
                <Row>
                    <Col sm={5} className='slider-titlec'>
                        <p className="slider-title">Galería</p>
                        <p className="slider-text">Explora nuestra galería y descubre la calidad de nuestros proyectos.</p>
                        <Link to="/Galeria" ><button className="slider-button">Galería</button></Link>
                    </Col>
                    <Col sm={7}>
                        <Link to="/Galeria"><img className="slider-images" src={gallery} alt="Gallery_image_link" /></Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Slider;