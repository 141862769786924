import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './main.css'
import treceMercantil from '../img/trece_mercantil.png';
import treceAlt from '../img/trece_alt.png';

function Main() {
    return (
        <div className="main-container">
            <Container>
                <Row xs={1} md={2}>
                    <Col>
                        <div className="image-container">
                            <img
                                className="main-image"
                                src={treceMercantil}
                                width="50%"
                                alt=""
                            />
                        </div>
                        <div className="imageAlt-container">
                        <img
                                className="alt-image"
                                src={treceAlt}
                                width="50%"
                                alt=""
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="main-text">
                            <p className="main-title">Vuelve realidad tu proyecto con TRECE</p>
                        </div>
                        <div className="main-subtext">
                            <p>En Trece, hacemos de tus ideas una realidad con soluciones integrales en construcción. Tu visión, nuestro compromiso.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Main;