import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from '../components/header';
import Footer from '../components/footer';
import './Us.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import stock8 from '../img/services/const_ind/constI2.jpeg';
import stock9 from '../img/gallery/gal13.jpeg';
import stock10 from '../img/services/otros/otro1.jpeg';

function Us() {
  return (
    <div>
      <AppHeader />
      <div className="us-container">
        <Container>
          <div className="">
            <p className="us-title">Nosotros</p>
          </div>
          <p className="us-text">
            TRECE es una empresa de construcción que nace en el año 2019, especializada en los mercados de minería, infraestructura civil, industria pesada y edificación en general.
            El equipo de TRECE cuenta con amplia experiencia fundamentado en calidad y seguridad en el lugar de trabajo ofreciendo conocimiento sustancial de construcción, precios competitivos, solidez financiera, integridad y un compromiso con su proyecto.
            TRECE tiene la capacidad de realizar el trabajo en cualquier parte de la república.
          </p>
          <Row xs={1} md={2}>

            <Col>
              <div className="usc-textl">
                <p className="usI-title">Misión</p>
                <p className="usI-text">Ser un equipo comprometido con la elaboración y ejecución de proyectos de la más alta calidad a diversas escalas en la industria de la construcción.</p>
              </div>
            </Col>
            <Col>
              <div className="usc-textr">
                <p className="usI-title">Visión</p>
                <p className="usI-text">Ser un equipo referente a nivel nacional en la industria de la construcción, ejecutando trabajos de calidad.</p>
              </div>
            </Col>

          </Row>
          <div className="us-imagescont">
            {/*<Row>
              <Col>
                <div className="us-imagec">
                  <img
                    className="us-image"
                    src={stock8}
                    alt=""
                  />
                </div>
              </Col>
            </Row>*/}
            <Row>
              <Col>
                <div className="us-imagec">
                  <img
                    className="us-image"
                    src={stock9}
                    alt=""
                  />
                </div>
              </Col>
              <Col>
                <div className="us-imagec">
                  <img
                    className="us-image"
                    src={stock10}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Us;