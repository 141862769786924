import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from '../components/header';
import Container from 'react-bootstrap/Container';
import MainImage from '../components/mainImage';
import Services from '../components/services';
import Main from '../components/main';
import Slider from '../components/slider';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Map from '../components/map';

function Home() {
  return (
    <div>
      <AppHeader/>
      <Main/>
      <Container>
        <MainImage/>
        <Services/>
        <Slider/>
        <Contact/>
        <Map/>
        </Container>
      <br/>
      <Footer/>
    </div>
  );
}

export default Home;